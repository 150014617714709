import React, { useEffect, useRef } from 'react'
import L from 'leaflet'
import { MapContainer, TileLayer, GeoJSON, ZoomControl } from 'react-leaflet'
import Layout from '../components/Layout'
// import places from "../data/places.json"
import places1865 from "../data/places1865.json"
import '../styles/map.css'
import dotMarker from "../images/noun_dot_3894734.png"


function useHasMounted() {
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    return hasMounted;
  }

export default function Map() {


    const createPopups = (feature = {}, layer) => {
        const { properties = {} } = feature
        const { name, type, certainty } = properties
        const popup = L.popup()
        const html = `
            <div class="popup-container">
                <h3 class="popup-header">${name}</h3>
                <p>The type is ${type}.</p>
                <p>The certainty of location is ${certainty}.</p>
            </div>
        `
        popup.setContent(html)
        layer.bindPopup(popup)
    }

    return (
        <Layout>
            <div className="container has-background-primary p-6">
                <h1 className="title has-text-black">Shackelford 1865 and 1868 Map</h1>
            </div>
            <div className="map">
                {useHasMounted && (
                    <MapContainer
                        center={[39.505, -99.09]}
                        zoom={5}
                        scrollWheelZoom={false}
                        zoomControl={false}
                    >
                        <TileLayer 
                        attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>. dot by Paweł Gleń from the Noun Project'
                        url="https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg"  
                        />
                        <ZoomControl position="bottomright" />
                        <GeoJSON data={places1865} onEachFeature={createPopups}/>
                    </MapContainer>
                )}
            </div>
        </Layout>
    )
}
